
import { defineComponent, reactive } from 'vue';
import {
  LeftOutlined,
  RightOutlined,
  CloudDownloadOutlined,
  CloudOutlined,
  DownOutlined,
  DownloadOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'ButtonExample',
  setup() {
    const state = reactive({
      loading: false,
      iconLoading: false as boolean | { delay: number },
      size: 'large',
    });
    const enterLoading = () => {
      state.loading = true;
    };
    const enterIconLoading = () => {
      state.iconLoading = { delay: 1000 };
    };
    const handleMenuClick = () => {};
    const handleSizeChange = (e: Event) => {
      state.size = (e.target as HTMLInputElement).value;
    };
    return {
      state,

      enterLoading,
      enterIconLoading,
      handleMenuClick,
      handleSizeChange,
    };
  },
  components: {
    LeftOutlined,
    RightOutlined,
    CloudDownloadOutlined,
    CloudOutlined,
    DownOutlined,
    DownloadOutlined,
  },
});
